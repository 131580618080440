import { Controller } from '@hotwired/stimulus'
import { showToast, getSuccessMessage } from './../showToast'

export default class extends Controller {
  static values = { url: String, message: String }

  copy() {
    navigator.clipboard.writeText(this.urlValue).then(() => {
      showToast('Copied to clipboard', this.messageValue, 'success')
    })
  }
}
