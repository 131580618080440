import { Controller } from '@hotwired/stimulus'
import {  enter, leave  } from "./../helpers/transition"

export default class extends Controller {
  static targets = ['image']

  connect() {
    var imgLarge = new Image();
    imgLarge.src = this.imageTarget.dataset.large; 
    //imgLarge.classList.add('object-cover');
    imgLarge.classList.add('z-2');
    imgLarge.onload = function () {
      imgLarge.classList.add('loaded');  
    };
    this.imageTarget.appendChild(imgLarge);
    this.imageTarget.querySelectorAll('svg').forEach(function(svg) {
      svg.remove();
    });
  }
}
