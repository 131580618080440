import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["submit", "bell"];
  static values = {
    previewUrl: String
  }

  connect () {
    this.preview = debounce(this.preview, 300)
  }


  disconnect() {
    this.enableSubmits();
    this.element.toggleAttribute("data-submitting", false);
  }

  preview = () => {
    const form = this.element;
    const url = form.getAttribute('action')
    form.setAttribute('action', this.previewUrlValue)
    form.requestSubmit()
    form.setAttribute('action', url)
    this.bellTarget.classList.remove('hidden')
  }

  submit(e) {
    this.bellTarget.classList.add('hidden')
    e.preventDefault()
    const form = this.element;
    if (form.getAttribute('data-uploading')) { return }

    form.requestSubmit()
  }

  disableSubmits() {
    this.submitTargets.forEach(
      function (submitTarget) {
        submitTarget.disabled = true;
      }
    );
  }

  enableSubmits() {
    this.submitTargets.forEach(
      function (submitTarget) {
        submitTarget.disabled = false;
      }
    );
  }

  submitStart() {
    const form = this.element;
    if (form) {
      form.toggleAttribute("data-submitting", true);
      this.disableSubmits();
    }
  }

  submitEnd() {
    const form = this.element;
    if (form) {
      form.toggleAttribute("data-submitting", false);
      this.enableSubmits();
    }
  }
}

const debounce = (fn, delay = 10) => {
  let timeoutId = null

  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(fn, delay)
  }
}
