import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["player", "playbutton"]
  static values = ["id"]

  connect() {
    this.boundBeforeCache = this.beforeCache.bind(this)
    document.addEventListener("turbo:before-cache", this.boundBeforeCache)
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.boundBeforeCache)
  }

  play() {
    this.playerTarget.play()
    this.playbuttonTarget.classList.add('hidden')

		posthog.capture(
			'rendered_video_played', 
			{ 
				video_id: this.idValue, 
			}
		)
  }

  pause() {
    this.playerTarget.pause()
    this.playbuttonTarget.classList.remove('hidden')
  }

  beforeCache() {
    if (this.playerTarget && !this.playerTarget.paused) { this.playerTarget.pause() }
  }
}
