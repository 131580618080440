import { Controller } from '@hotwired/stimulus'
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = { videoId: Number }

  checkCaptions = async () => {
		const response = await get(`/api/v1/videos/${this.videoIdValue}`, {
			responseKind: "application/json",
		})

		if (response.ok) {
			const json = await response.json
      if (json.captionsStatus === true) {
        window.location.reload();
      } else {
        setTimeout(() => this.checkCaptions(), 3000);
      }
    }
  }

  connect() {
    setTimeout(() => {
      this.checkCaptions()
    }, 5000)
  }
}
