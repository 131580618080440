import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "toggle", "secondarytoggle" ]
  static targets = [ "transitionable", "secondary", "thesidebar" ]
  static values = { afterTimeout: Number }

  connect() {
    if (this.afterTimeoutValue) setTimeout(() => this.toggleClass(), this.afterTimeoutValue)
  }

  toggleClass() {
    this.thesidebarTargets.forEach(element => {
      this.toggleClasses.forEach(className => {
        element.classList.toggle(['hidden'])
      })
    })
    this.secondaryTargets.forEach(element => {
      this.secondarytoggleClasses.forEach(className => {
        element.classList.toggle(['hidden'])
      })
    })
  }

  mouseOver() {
    this.thesidebarTarget.classList.remove('lg:w-16','w-0')
    this.thesidebarTarget.classList.add('lg:w-64')
  }
  mouseOut() {
    this.thesidebarTarget.classList.add('lg:w-16','w-0')
    this.thesidebarTarget.classList.remove('lg:w-64')
  }
}
